<template>
    <div class="col-12 d-flex align-items-center" :class="[
      { 'mb-1 justify-content-end': watchListType == 'NEW_MARKET' },
      { 'mb-4': watchListType != 'NEW_MARKET' },
    ]" v-if="store.user.access_token && getMarketWatchList().length">
        <h6 class="mb-0 bold f-22 me-3" v-if="watchListType != 'NEW_MARKET'">
            {{ $t("watchlist_widget.watchlist") }}
        </h6>
        <!-- <router-link to="/watchlist" class="viewAll gray medium f-14 d-flex align-items-center">
            {{ $t("top_traders.viewAll") }}
            <vue-feather class="ms-1" type="chevron-right" size="16"></vue-feather>
        </router-link> -->
    </div>
    <div class="overflow-auto" v-if="watchListType == 'NEW_MARKET'">
        <ul class="loopList" v-if="store.user.access_token">
            <li :class="[{'active':value.id == $parent.marketSelected.id}]" v-for="(value, key) in getMarketWatchList().length ? getMarketWatchList() : getPopularCurr.slice(0, 12)" v-bind:key="key">
                <div class="customTradeAdd shadow-none"
                    v-if="Object.keys(value).length">
                    <div class="card-body px-2 py-2 text-center position-relative flex-between pointer" @click="$parent.getSetID(value)">
                        <div class="vueRisk d-flex align-items-center">
                            <span class="mb-0 d-flex defineCircle">
                                <span class="d-inline-flex mb-1" v-if="value.name">
                                    <v-lazy-image class="euCurrency" :src="static_vars.marketImageSURL +
                                        value.name.toUpperCase().replace(/\//g, '') + '.svg'" :alt="value.name.toString().split('/')[0].toLowerCase()" :title="value.name.toString().split('/')[0].toLowerCase()" />
                                </span>
                            </span>
                            <span class="mb-0 d-block">
                                <h6 class="mb-0 medium neutralGrey f-15">{{ value.name }}</h6>
                            </span>
                        </div>
                        <div class="title mb-0">
                            <div class="element">
                                <div
                                    class="elementHub">
                                    <p class="mb-0 me-2 p-0 neutralGrey f-15">
                                        {{
                                            value.name in store.allPrices && store.allPrices[value.name]
                                            ? store.allPrices[value.name].buyPrice
                                            : value.currentPrice
                                            }}
                                    </p>
                                    <p class="mb-0 py-0 px-1 d-inline-flex align-items-center f-14" :class=" parseFloat(value.dailyPriceChangePercentage || value.lastChangePercentage) > 0.0 ? 'green' : 'red' ">
                                    <i :class="parseFloat(value.dailyPriceChangePercentage || value.lastChangePercentage) > 0.0 ? 'fa fa-caret-up me-1' : 'fa fa-caret-down me-1'" aria-hidden="true"></i>
                                    {{ parseFloat(value.dailyPriceChangePercentage || value.lastChangePercentage) > 0.0 ? "+" : ""}}{{ parseFloat(value.dailyPriceChangePercentage || value.lastChangePercentage) ? parseFloat(value.dailyPriceChangePercentage || value.lastChangePercentage).toFixed(2) : 0 }}%
                                    </p>
                                </div>
                            </div >
                        </div >
                        <div class="abTrade">
                            <div class="d-flex align-items-center justify-content-center tradeType">
                                <a href="javascript:void(0)"
                                    class="button buyBtn fillBtn zulu_btn me-2" @click.stop="
                                        store.user.access_token ? (tradeType = 'buy') : (showLoginPopup = true);
                                        instrumentDetail.symbol = value.name;
                                        instrumentDetail.dailyPriceChangePercentage = value.dailyPriceChangePercentage || value.lastChangePercentage;" 
                                        :class="{ 'disabled': store.customerDetail?.readOnly }">
                                    {{ $t("home.text78") }}</a>
                                <a href="javascript:void(0)" class="button sellBtn fillBtn zulu_btn"
                                    @click.stop="
                                        store.user.access_token ? (tradeType = 'sell') : (showLoginPopup = true);
                                        instrumentDetail.symbol = value.name;
                                        instrumentDetail.dailyPriceChangePercentage = value.dailyPriceChangePercentage || value.lastChangePercentage;" 
                                        :class="{ 'disabled': store.customerDetail?.readOnly } ">
                                        {{ $t("home.text79") }}</a >
                            </div>
                            <a class="star inLarge" href="javascript:void(0)"
                                @click.stop="$parent.AddWatchList(value)"
                                :class="{ 'disabled': store.customerDetail?.readOnly }"><vue-feather
                                    type="star" size="19"
                                    :class="[{ filled: $parent.isFollowed(value.id) }]"></vue-feather>
                            </a>
                        </div>
                    </div>
                </div>
            </li>
            <Nodata v-if="!getMarketWatchList().length && !getPopularCurr.length"></Nodata>
        </ul>
        <!-- <ul class="loopList d-flex align-items-center flex-wrap mt-0 mb-1"
            v-if="store.user.access_token && getMarketWatchList().length">
            <li v-for="(value, key) in getMarketWatchList().slice(0, 20)" v-bind:key="key">
                <div class="card shadow-none border-cstm w-100 h-100">
                    <div class="card-body text-center position-relative px-0 pb-0">
                        <div class="position-relative vueRisk">
                            <a class="position-absolute star" href="javascript:void(0)"
                                :class="{ 'disabled': store.customerDetail?.readOnly }"
                                @click="$parent.AddWatchList(value)">
                                <vue-feather type="star" size="19"
                                    :class="[{ filled: $parent.isFollowed(value.id) }]"></vue-feather>
                            </a>
                            <span class="circleImg border-radius-0 d-inline-block mb-2">
                                <router-link :to="{
                                    name: 'market_details',
                                    params: {
                                    id: value.id,
                                    currency: value.name.includes('/')
                                        ? value.name.split('/').join('-')
                                        : value.name,
                                    },
                                }">
                                    <v-lazy-image class="img-fluid" :src="
                                        static_vars.marketImageSURL +
                                        value.name.toUpperCase().replace(/\//g, '') +
                                        '.svg'
                                        " :alt="value.name.toString().split('/')[0].toLowerCase()"
                                        :title="value.name.toString().split('/')[0].toLowerCase()" />
                                </router-link>
                            </span>
                        </div>
                        <a href="javascript:void(0)">
                            <router-link :to="{
                                name: 'market_details',
                                params: {
                                    id: value.id,
                                    currency: value.name.includes('/')
                                    ? value.name.split('/').join('-')
                                    : value.name,
                                },
                                }">
                                <h6 class="mb-2 medium f-16">{{ value.name }}</h6>
                            </router-link>
                        </a>
                        <div class="element d-flex align-items-center justify-content-center">
                            <p class="mb-0 bold me-1">
                                {{
                                value.name in store.allPrices && store.allPrices[value.name]
                                ? store.allPrices[value.name].buyPrice
                                : value.currentPrice
                                }}
                            </p>
                            <p class="mb-0 px-1 medium d-inline-flex" :class="
                                parseFloat(value.dailyPriceChangePercentage) > 0.0
                                    ? 'greenView'
                                    : 'redView'
                                ">
                                {{ parseFloat(value.dailyPriceChangePercentage) > 0.0 ? "+" : ""
                                }}{{
                                parseFloat(value.dailyPriceChangePercentage)
                                ? parseFloat(value.dailyPriceChangePercentage).toFixed(2)
                                : 0
                                }}%
                            </p>
                        </div>
                        <div class="chartElement chartCalcHeight" :id="'mixedcurrency' + value.id"
                            style="width: 100%; height: 126px">
                        </div>
                    </div>
                </div>
            </li>
        </ul> -->
        <div class="mt-5 text-center calcError" v-else>
            <i><v-lazy-image src="/assets/images/watchlist/empty-star.png"
                    :alt="$t('watchlist_widget.watchlistEmpty')" /></i>
            <h6 class="mb-0 mt-2 f-20 medium fst-normal">
                {{ $t("watchlist_widget.watchlistEmpty") }}
            </h6>
            <button class="button borderBtn small mt-2 bg-transparent medium px-4 py-2" @click="addMoreWatchlist = 2">
                {{ $t("watchlist_widget.AddMoreAssets") }}
            </button>
        </div>
    </div>
    <carousel class="mb-3" :settings="carousleSettingsIn" :breakpoints="breakpointsIn" v-if="
      store.user.access_token &&
      getMarketWatchList().length &&
      watchListType != 'NEW_MARKET'
    ">
        <slide v-for="(value, key) in getMarketWatchList().slice(0, 20)" v-bind:key="key">
            <div class="card shadow-none border-cstm w-100 h-100">
                <div class="card-body text-center position-relative px-0 pb-0">
                    <div class="position-relative vueRisk" v-if="watchListType == 'MARKET'">
                        <a class="position-absolute star" href="javascript:void(0)"
                            :class="{ 'disabled': store.customerDetail?.readOnly }"
                            @click="$parent.AddWatchList(value)">
                            <vue-feather type="star" size="19"
                                :class="[{ filled: $parent.isFollowed(value.id) }]"></vue-feather>
                        </a>
                        <span class="circleImg border-radius-0 d-inline-block mb-2">
                            <router-link :to="{ name: 'rates', query: { insID: value.id }}">
                                <v-lazy-image class="img-fluid" :src="
                    static_vars.marketImageSURL +
                    value.name.toUpperCase().replace(/\//g, '') +
                    '.svg'
                  " :alt="value.name.toString().split('/')[0].toLowerCase()"
                                    :title="value.name.toString().split('/')[0].toLowerCase()" />
                            </router-link>
                        </span>
                    </div>
                    <div class="traderStock" v-else>
                        <div class="vueRisk px-3 d-flex align-items-start justify-content-between">
                            <p :title="static_vars.riskTooltip" class="bTn mb-0 f-12 bold uppercase" :class="[
                  { blueBtn: parseFloat(value.risk) == 1 },
                  { greenBtn: parseFloat(value.risk) == 2 },
                  { yellowBtn: parseFloat(value.risk) == 3 },
                  { redBtn: parseFloat(value.risk) == 4 },
                  { pinkBtn: parseFloat(value.risk) == 5 },
                ]" v-if="value.risk">
                                {{ $t("top_traders.risk") }} {{ value.risk }}
                            </p>
                            <div class="d-flex align-items-center ashish">
                                <a class="star me-1" :class="{ 'disabled': store.customerDetail?.readOnly }"
                                    href="javascript:void(0)"
                                    @click="$parent.WatchListWidgetAddWatchList(value)"><vue-feather type="star"
                                        size="19" :class="[{ filled: $parent.isFollowed(value.id) }]"></vue-feather></a>
                                <div class="position-relative">
                                    <a href="javascript:void(0)" class="iconButton ms-1" @click="
                      opendots != value.id ? (opendots = value.id) : (opendots = -1)
                    ">
                                        <i class="gray fa fa-ellipsis-v" aria-hidden="true"></i>
                                    </a>
                                    <ul class="dropdown-menu" data-popper-placement="bottom-start"
                                        :class="[{ show: opendots == value.id }]">
                                        <li>
                                            <a class="f-12 dropdown-item" href="javascript:void(0);"
                                                @click="sharePop = value.id">{{ $t("top_traders.share") }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <span class="circleImg d-inline-block mb-1">
                            <router-link :to="`/trader/${value.id}/trading?t=10000&m=1`">
                                <v-lazy-image :src="
                    !value.photoApproved
                      ? value.customerAvatarUrl
                        ? value.customerAvatarUrl
                        : static_vars.imagesURL +
                          'webservices/Image.ashx?type=provider&size=XL&id=' +
                          value.id +
                          '&ignore=false'
                      : static_vars.imagesURL +
                        'webservices/Image.ashx?type=provider&size=XL&id=' +
                        value.id +
                        '&ignore=false'
                  " :alt="value.name" :title="value.name" />
                            </router-link>
                        </span>
                    </div>
                    <a href="javascript:void(0)">
                        <router-link v-if="watchListType == 'MARKET'" :to="{ name: 'rates', query: {insID: value.id}}">
                            <h6 class="mb-2 medium f-16">{{ value.name }}</h6>
                        </router-link>
                        <router-link v-else :to="`/trader/${value.id}/trading?t=10000&m=1`">
                            <h6 class="mb-2 medium f-16">{{ value.name }}</h6>
                        </router-link>
                        <!-- <p class="mb-2 f-14 gray">TSLA</p> -->
                    </a>
                    <div class="element d-flex align-items-center justify-content-center"
                        v-if="watchListType == 'MARKET'">
                        <p class="mb-0 bold me-1">
                            {{
                            value.name in store.allPrices && store.allPrices[value.name]
                            ? store.allPrices[value.name].buyPrice
                            : value.currentPrice
                            }}
                        </p>
                        <p class="mb-0 px-1 medium d-inline-flex" :class="
                parseFloat(value.dailyPriceChangePercentage) > 0.0
                  ? 'greenView'
                  : 'redView'
              ">
                            {{ parseFloat(value.dailyPriceChangePercentage) > 0.0 ? "+" : ""
                            }}{{
                            parseFloat(value.dailyPriceChangePercentage)
                            ? parseFloat(value.dailyPriceChangePercentage).toFixed(2)
                            : 0
                            }}%
                        </p>
                    </div>
                    <div class="element d-flex align-items-center justify-content-center" v-else>
                        <p class="mb-0 px-1 medium d-inline-flex"
                            :class="parseFloat(value.roi) > 0.0 ? 'greenView' : 'redView'">
                            {{ parseFloat(value.roi) > 0.0 ? "+" : ""
                            }}{{ parseFloat(value.roi).toFixed(2) }}%
                        </p>
                    </div>
                    <div class="chartElement chartCalcHeight" :id="'mixedcurrency' + value.id"
                        style="width: 100%; height: 126px"></div>
                </div>
            </div>
        </slide>
        <template #addons="{ slidesCount }">
            <navigation v-if="slidesCount > 5" />
        </template>
    </carousel>
    <sharePop :shareURL="sharePop" :close="() => (sharePop = '')" v-if="sharePop" :shareType="'leaderProfile'">
    </sharePop>
    <loginPopup v-if="showLoginPopup"></loginPopup>
    <BuySell v-if="tradeType" />
    <!-- Search Watchlist Popup -->
    <div class="modal show fade searchTradeWatchlist" style="display: block" v-if="addMoreWatchlist == 2">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header bg-white border-0">
                    <h5 class="modal-title">{{ $t("watchlist_widget.AddMoreAssets") }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        @click="addMoreWatchlist = 0"></button>
                </div>
                <div class="modal-body pt-3 text-center">
                    <Search :tab="'MARKET'" :defaultList="'yes'" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    import * as am5 from "@amcharts/amcharts5";
    import * as am5xy from "@amcharts/amcharts5/xy";
    import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
    import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
    import { Carousel, Slide, Navigation } from "vue3-carousel";
    import sharePop from "@/views/social-feed/share-pop";
    import Search from "@/components/global-search/search-watchlist.vue";
    import BuySell from "@/views/dashboard-new/buy-sell";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        components: {
            Carousel,
            Slide,
            Navigation,
            sharePop,
            Search,
            BuySell,
        },
        props: {
            watchListType: {
                default: "",
            },
        },
        data() {
            return {
                addMoreWatchlist: 0,
                showLoginPopup: false,
                sharePop: "",
                opendots: false,
                carousleSettingsIn: {
                    itemsToShow: 6.5,
                    itemsToScroll: 5,
                    // mouseDrag: false,
                    // touchDrag: true,
                    snapAlign: "start",
                },
                breakpointsIn: {
                    320: {
                        itemsToShow: 1,
                        itemsToScroll: 1,
                        snapAlign: "start",
                    },
                    767: {
                        itemsToShow: 2.5,
                        itemsToScroll: 3,
                        snapAlign: "start",
                    },
                    1024: {
                        itemsToShow: 3.5,
                        itemsToScroll: 4,
                        snapAlign: "start",
                    },
                    1199: {
                        itemsToShow: 4.5,
                        itemsToScroll: 5,
                        snapAlign: "start",
                    },
                    1499: {
                        itemsToShow: 6.5,
                        itemsToScroll: 6,
                        snapAlign: "start",
                    },
                },
                tradeType: "",
                instrumentDetail: { symbol: "", dailyPriceChangePercentage: 0 },
            };
        },
        computed : {
            getPopularCurr(){
                if(this.store.categoryWiseMarket?.mostPopular && this.store.categoryWiseMarket?.mostPopular.length){
                    return this.store.categoryWiseMarket?.mostPopular
                }else{
                    return []
                }
            }
        },
        watch: {
            "store.watchList"() {
                if (this.watchListType == "MARKET") {
                    // || this.watchListType == "NEW_MARKET"
                    if (
                        this.store.user.access_token &&
                        this.store.watchList.length &&
                        this.getMarketWatchList().length
                    ) {
                        this.getMarketWatchList().forEach((val) => {
                            if (val.chart && val.chart.data && val.chart.data.length) {
                                setTimeout(() => {
                                    this.MixedCurrencyChart(`mixedcurrency${val.id}`, val.chart.data);
                                }, 1000);
                            }
                        });
                    }
                }
                if (this.watchListType == "LEADER") {
                    if (
                        this.store.user.access_token &&
                        this.store.leaderWatchList.length &&
                        this.getMarketWatchList().length
                    ) {
                        this.getMarketWatchList().forEach((val) => {
                            if (val.chart && val.chart.data && val.chart.data.length) {
                                setTimeout(() => {
                                    this.MixedCurrencyChart(`mixedcurrency${val.id}`, val.chart.data);
                                }, 1000);
                            }
                        });
                    }
                }
                // if (this.store.user.access_token && this.store.leaderWatchList.length && this.getMarketWatchList().length && this.watchListType == 'LEADER') {
                //     this.getMarketWatchList().forEach((val) => {
                //         if (val.chart && val.chart.data && val.chart.data.length) {
                //             setTimeout(() => {
                //                 this.MixedCurrencyChart(`mixedcurrency${val.id}`, val.chart.data);
                //             }, 1000);
                //         }
                //     });
                // }
            },
        },
        methods: {
            getBaseValue(data) {
                if (data.length) {
                    let value = data.map((i) => i.y);
                    if (value.length) {
                        // console.log(Math.min(...value))
                        return Math.min(...value);
                    } else {
                        return 0;
                    }
                }
            },
            getMarketWatchList() {
                if (
                    this.store.watchList.length &&
                    (this.watchListType == "MARKET" || this.watchListType == "NEW_MARKET")
                ) {
                    return this.store.watchList.filter((val) => val.type == "MARKET");
                }
                if (this.store.leaderWatchList.length && this.watchListType == "LEADER") {
                    return this.store.leaderWatchList.filter((val) => val.type == this.watchListType);
                } else {
                    return [];
                }
            },

            MixedCurrencyChart(id, data) {
                let chartId = id;
                let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === chartId);
                d?.dispose();
                var root = am5.Root.new(chartId);
                root.numberFormatter.set("numberFormat", "#,###.00");
                root.dateFormatter.setAll({
                    dateFormat:
                        this.watchListType == "MARKET" || this.watchListType == "NEW_MARKET"
                            ? "dd MMM yyyy HH:mm"
                            : "dd MMM yyyy",
                    dateFields: ["valueX"],
                });
                root.setThemes([am5themes_Animated.new(root), am5themes_Responsive.new(root)]);
                var chart = root.container.children.push(
                    am5xy.XYChart.new(root, {
                        focusable: true,
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                    })
                );

                // Add cursor
                // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
                var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
                cursor.lineY.set("visible", false);
                cursor.lineX.set("visible", false);
                // Create axes
                // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
                var xAxis = chart.xAxes.push(
                    am5xy.DateAxis.new(root, {
                        groupData: true,
                        visible: false,
                        startLocation: 0.5,
                        endLocation: 0.1,
                        baseInterval: {
                            timeUnit:
                                this.watchListType == "MARKET" || this.watchListType == "NEW_MARKET"
                                    ? "hour"
                                    : "day",
                            count: 1,
                        },
                        renderer: am5xy.AxisRendererX.new(root, {
                            // minGridDistance: 10,
                        }),
                    })
                );
                var yAxis = chart.yAxes.push(
                    am5xy.ValueAxis.new(root, {
                        visible: false,
                        strictMinMax: true,
                        extraMax: 0.03,
                        extraMin: 0.001,
                        baseValue: this.getBaseValue(data),
                        renderer: am5xy.AxisRendererY.new(root, {}),
                    })
                );
                xAxis.get("renderer").grid.template.set("forceHidden", true);
                yAxis.get("renderer").grid.template.set("forceHidden", true);
                chart.get("colors").set("colors", [am5.color(0xff7200)]);

                var series = chart.series.push(
                    am5xy.SmoothedXLineSeries.new(root, {
                        minBulletDistance: 10,
                        name: "Series",
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: "y",
                        valueXField: "x",
                        tooltip: am5.Tooltip.new(root, {
                            labelText:
                                this.watchListType == "MARKET" || this.watchListType == "NEW_MARKET"
                                    ? "[bold]Date[/]: {valueX.formatDate('dd MMM yyyy HH:mm')} \n [bold]Price[/] : {valueY}"
                                    : "[bold]Date[/]: {valueX.formatDate('dd MMM yyyy')}  \n [bold]ROI(%)[/] : {valueY}",
                        }),
                    })
                );
                series.fills.template.setAll({
                    visible: true,
                    fillOpacity: 0.1,
                });
                // series.fills.template.set(
                //     "fillGradient",
                //     am5.LinearGradient.new(root, {
                //         stops: [ { opacity: 0.1, }, { opacity: 0.04, }, ],
                //     })
                // );
                series.strokes.template.setAll({
                    strokeWidth: 2,
                });
                // Set data
                // if(data.length == 1){
                //     data[1] = {x:1676365200000,y: 0}
                //     console.log(data)
                // }
                series.data.setAll(data);
                //legend.data.push(series);
                series.appear(1000);
                chart.zoomOutButton.set("forceHidden", true);
                chart.appear(1000, 100);
            },
        },
        mounted() {
            if (this.watchListType == "MARKET") {
                // || this.watchListType == "NEW_MARKET"
                if (
                    this.store.user.access_token &&
                    this.store.watchList.length &&
                    this.getMarketWatchList().length
                ) {
                    this.getMarketWatchList().forEach((val) => {
                        if (val.chart && val.chart.data && val.chart.data.length) {
                            setTimeout(() => {
                                this.MixedCurrencyChart(`mixedcurrency${val.id}`, val.chart.data);
                            }, 1000);
                        }
                    });
                }
            }
            if (this.watchListType == "LEADER") {
                if (
                    this.store.user.access_token &&
                    this.store.leaderWatchList.length &&
                    this.getMarketWatchList().length
                ) {
                    this.getMarketWatchList().forEach((val) => {
                        if (val.chart && val.chart.data && val.chart.data.length) {
                            setTimeout(() => {
                                this.MixedCurrencyChart(`mixedcurrency${val.id}`, val.chart.data);
                            }, 1000);
                        }
                    });
                }
            }
        },
    };
</script>